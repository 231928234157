import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgPlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height="24px" viewBox="0 0 16 17" width="24px" {...props}>
    <path
      d="M8 3.833v9.334M3.333 8.5h9.334"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);
const Memo = memo(SvgPlusIcon);
export default Memo;
